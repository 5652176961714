import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import SEO from '../components/Seo';
import { Container, serializers } from '../templates/PageTemplate';

export default function UpdatesPage({ data }) {
  const { allSanityUpdate } = data;
  const { nodes } = allSanityUpdate

  return (
    <>
      <SEO
        title="News & Updates"
      />
      <Container
        className="t-pb-12"
      >
        <section className="t-h-1/2screen t-bg-transparent t-flex t-items-start t-flex-col t-container t-mx-auto t-justify-center">
          <div className="t-relative">
            <p className="t-bg-purple-1000-alpha t-text-white t-text-8xl t-leading-none t-p-4 t-uppercase t-font-bold">
              News & Updates
            </p>
          </div>
        </section>
        {
          nodes.map(({ _rawContent, slug }) => (
            <BlockContent
              key={slug}
              blocks={_rawContent}
              projectId={process.env.GATSBY_SANITY_PROJECT_ID}
              dataset={process.env.GATSBY_SANITY_DATASET}
              serializers={serializers(slug.current)}
            />
          ))
        }
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allSanityUpdate(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        slug {
          current
        }
        _rawContent(resolveReferences: {maxDepth: 10})
      }
    }
  }
`;
